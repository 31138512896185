import React from "react";
import CalendarDayComponent from "./CalendarDayComponent";
import {
  getMonthName,
  getDay,
  formatAMPMTime,
  dateHasPassedCheck,
} from "./dateHelpers";
import DeleteEventIcon from "./DeleteEventIcon";

export default function ScheduledEventsListItem({ event }) {
  const startDateTime = new Date(event.start_ts).toLocaleString();
  const startDateMonth = getMonthName(startDateTime);
  const eventDay = getDay(startDateTime);
  const status = event.status.toLowerCase();
  const hasPassed = dateHasPassedCheck(startDateTime)
    ? "hasPassed"
    : "hasNotPassed";

  return (
    <li className={`eventItemContainer ${hasPassed}`}>
      <CalendarDayComponent month={startDateMonth} day={eventDay} />
      <div className="data">
        <p>
          <span className="eventLabel">Status: </span>
          <span className={`statusText ${status}Text`}>{event.status}</span>
        </p>
        <div>
          <p className="eventLabel">Start Time:</p>
          <p>{formatAMPMTime(startDateTime)}</p>
        </div>
        <div>
          <p className="eventLabel">Description:</p>
          <p>{event.description}</p>
        </div>
      </div>
      <DeleteEventIcon
        eventId={event.id}
        eventStatus={event.status}
        startDateTime={startDateTime}
      />
    </li>
  );
}
