import React from "react";

export default function InputDropdown({ name, label, options, currentValue, onChange }) {
  function handleOnChange({target}) {
    onChange(target.value);
  }

  return (
    <>
      <label className="bold" htmlFor={name}>
        {label}
      </label>
      <select
        className="select"
        id={name}
        name={name}
        value={currentValue}
        onChange={handleOnChange}
      >
        {options.map((option) => {
          return (
            <option value={option} key={option}>
              {option}
            </option>
          );
        })}
      </select>
    </>
  );
}

