import React from "react";

function InputBox({
  cols,
  label,
  name,
  onChange,
  rows,
  style,
  type,
  useTextArea,
  value,
}) {
  function handleOnChange({target}) {
    onChange(target.value);
  }

  return (
    <div className="inputBoxContainer">
      <label className="bold" htmlFor={name}>
        {label}
      </label>
      {!useTextArea && (
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={handleOnChange}
          style={style}
        />
      )}
      {useTextArea && (
        <textarea
          id={name}
          name={name}
          cols={cols}
          maxLength={255}
          onChange={handleOnChange}
          rows={rows}
          style={style}
          type={type}
          value={value}
        />
      )}
    </div>
  );
}

export default InputBox;
