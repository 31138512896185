export async function getEventsList(accessToken) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${accessToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    // body: urlencoded, does not seem to like including this
    redirect: "follow",
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASEURL}/v1-preview/orgs/scheels/scheduled-scaling-events`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result.events)
    .catch((error) => console.log("error", error));

  return response;
}
