import React from "react";

function GlobalButton({
  title,
  onClick,
  backgroundColor,
  textColor,
  borderColor,
}) {
  return (
    <div
      className="globalButton"
      style={{
        color: textColor,
        backgroundColor,
        border: `1px solid ${borderColor}`,
      }}
      onClick={onClick}
    >
      {title}
    </div>
  );
}

export default GlobalButton;
