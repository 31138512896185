import moment from "moment";

export function getMonthName(date) {
  const dateFormat = new Date(date);

  return moment(dateFormat).format("MMM");
}

export function getDay(date) {
  const dateFormat = new Date(date);

  return moment(dateFormat).format("D");
}

export function formatAMPMTime(date) {
  const dateFormat = new Date(date);

  return moment(dateFormat).format("h:mm a") + " " + getCurrentTimeZone();
}

export function getCurrentTimeZone() {
  const day = new Date();
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "long" });
  const timeZoneName = dtf
    .formatToParts(day)
    .find((part) => part.type == "timeZoneName").value;
  return timeZoneName;
}

export function dateHasPassedCheck(date) {
  const today = new Date();
  const comparingDate = new Date(date);

  return today >= comparingDate;
}

export function getTomorrowDate() {
  const today = new Date();
  const tomorrow = new Date(today);
  return new Date(tomorrow.setDate(tomorrow.getDate() + 1));
}
