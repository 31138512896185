import React from "react";
import GlobalButton from "./GlobalButton";
import { getEventsList } from "./getEventsList";

function DeleteEventIcon({ eventId, accessToken, setIsOpen }) {
  function handleOnClickCancel() {
    setIsOpen(false);
  }

  function handleOnClickDeleteEvent() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASEURL}/v1-preview/orgs/scheels/scheduled-scaling-events/${eventId}`,
      requestOptions
    )
      .then((response) => getEventsList(accessToken))
      .then((result) => {
        getEventsList(accessToken);
        window.location.reload(false);
      })
      .catch((error) => console.log("error", error));
  }

  return (
    <div className="confirmationContainer">
      <div className="confirmationText">
        Are you sure you want to delete this event?
      </div>
      <div className="buttonContainer">
        <GlobalButton
          title="Cancel"
          onClick={handleOnClickCancel}
          backgroundColor="white"
          textColor="black"
        />
        <GlobalButton
          title="DELETE"
          onClick={handleOnClickDeleteEvent}
          backgroundColor="#DA1A32"
          textColor="white"
        />
      </div>
    </div>
  );
}

export default DeleteEventIcon;
