import React from "react";

function CalendarDayComponent({ month, day }) {
  return (
    <div className="container">
      <div className="month">{month}</div>
      <div className="day">{day}</div>
    </div>
  );
}

export default CalendarDayComponent;
