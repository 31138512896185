import React from "react";

function ScheduleEventRules() {
  return (
    <>
      <h2 className="rules">Rules for scheduling events:</h2>
      
      <ul className="ruleList">
        <li>Can't schedule an event less than 1 hour in the future</li>
        <li>Can't schedule an event more then 60 days in the future</li>
        <li>Can't schedule an event start inside another event span</li>
        <li>Can't schedule more than 100 events at a time</li>
      </ul>
    </>
  );
}

export default ScheduleEventRules;
