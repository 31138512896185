import React, { useState } from "react";
import ScheduledEventsList from "./ScheduledEventsList";
import ScheduleEventForm from "./ScheduleEventForm";
import EventStatusFilter from "./EventStatusFilter";

function Content({ eventsList, accessToken, formIsOpen, setFormIsOpen }) {
  const [filterOption, setFilterOption] = useState("SCHEDULED");

  function onChangeFilter(value) {
    setFilterOption(value);
  }

  function createFilteredList() {
    if (filterOption !== "ALL") {
      const newList = eventsList.filter((item) => {
        return item.status === filterOption;
      });

      return newList;
    } else {
      return eventsList;
    }
  }
  
  return (
    <div className="content">
      <div className="contentContainer">
        <EventStatusFilter
          inputValues={["ALL", "SCHEDULED", "CANCELED", "COMPLETE"]}
          onChange={onChangeFilter}
          currentValue={filterOption}
        />
        <ScheduledEventsList
          eventsList={createFilteredList()}
          accessToken={accessToken}
        />
      </div>
      {formIsOpen && (
        <ScheduleEventForm
          setFormIsOpen={setFormIsOpen}
          accessToken={accessToken}
        />
      )}
    </div>
  );
}

export default Content;
