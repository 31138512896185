import React from "react";

function EventStatusFilter({ inputValues, onChange, currentValue }) {
  return (
    <div className="radioButtonContainer">
      {inputValues.map((value) => {
        return (
          <div
            key={value}
            onClick={(event) => {
              onChange(value);
            }}
          >
            <input
              type="radio"
              value={value}
              name={value}
              checked={currentValue === value}
              onChange={(event) => onChange(event.target.value)}
            />{" "}
            {value}
          </div>
        );
      })}
    </div>
  );
}

export default EventStatusFilter;
