import logo from "./images/scheels_logo.png";
import GlobalButton from "./GlobalButton";

export default function Header({ setFormIsOpen }) {
  function handleOnClickButton() {
    setFormIsOpen(true);
  }

  return (
    <div className="header">
      <img src={logo} alt="scheels logo" className="icon" />
      <h1 className="title">
        Scheels Mobile App Push Notification Scheduler
      </h1>
      <GlobalButton
        title="Schedule New Event"
        backgroundColor="#DA1A32"
        textColor="white"
        onClick={handleOnClickButton}
      />
    </div>
  );
}

