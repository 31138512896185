import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import DeleteEventConfirmation from "./DeleteEventConfirmation";
import { dateHasPassedCheck } from "./dateHelpers";

function DeleteEventIcon({ eventId, eventStatus, startDateTime, accessToken }) {
  const [isOpen, setIsOpen] = useState(false);
  const dateHasPassed = dateHasPassedCheck(startDateTime);

  function handleOnClickDeletePrompt() {
    setIsOpen(true);
  }

  if (eventStatus !== "SCHEDULED" || dateHasPassed) {
    return null;
  }

  return (
    <div className="iconContainer">
      {isOpen && (
        <DeleteEventConfirmation
          eventId={eventId}
          setIsOpen={setIsOpen}
          accessToken={accessToken}
        />
      )}
      <div className="iconPadding" onClick={handleOnClickDeletePrompt}>
        <BiTrash size={36} color={"white"} />
      </div>
    </div>
  );
}

export default DeleteEventIcon;
