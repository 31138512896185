import React from "react";
import NoResultsView from "./NoResultsView";
import ScheduledEventsListItem from "./ScheduledEventsListItem";

export default function ScheduledEventsList({ eventsList, accessToken }) {
  const noResults = eventsList?.length < 1;

  function renderContent() {
    if (noResults) {
      return <NoResultsView />;
    } else {
      return eventsList?.map((event) => (
        <ScheduledEventsListItem
          event={event}
          key={event.id}
          accessToken={accessToken}
        />
      ));
    }
  }

  return (
    <>
      <h2 className="eventsTitle">Scheduled Events</h2>
      <ul className="listContainer">
        {renderContent()}
      </ul>
    </>
  );
}

