import React, { useState } from "react";
import ScheduleEventRules from "./ScheduleEventRules";
import InputBox from "./InputBox";
import InputDropdown from "./InputDropdown";
import GlobalButton from "./GlobalButton";
import DateTimePicker from "react-datetime-picker";
import { getEventsList } from "./getEventsList";

export default function ScheduleEventForm({ accessToken, setFormIsOpen }) {
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("LARGE");
  const [dateTime, setDateTime] = useState();

  function handleOnClickCloseForm() {
    setFormIsOpen(false);
  }

  function handleSubmit() {
    if (!dateTime) {
      alert("You must enter in a valid Start Date Time");
    } else {
      const startTime = new Date(dateTime);
      const endTime = new Date(dateTime.setHours(dateTime.getHours() + 1)); //put end time one hour in the future

      const formSubmitInformation = {
        description: description,
        end_ts: endTime.toISOString(),
        size: size,
        start_ts: startTime.toISOString(),
      };
      alert("submitting");

      //if success, close form
      //if error, try and display error
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(formSubmitInformation);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASEURL}/v1-preview/orgs/scheels/scheduled-scaling-events`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          handleOnClickCloseForm();
          getEventsList(accessToken);
          window.location.reload(false);
        })
        .catch((error) => alert(`ERROR: ${error}`));
    }
  }

  return (
    <div className="sneezeGuard">
      <div className="formContainer">
        <form onSubmit={handleSubmit} className="form">
          <ScheduleEventRules />
          <div className="dateTimeContainer">
            <label>Start Date and Time of Push (Central Time)*</label>
            <DateTimePicker
              onChange={setDateTime}
              value={dateTime}
              minDate={new Date()}
            />
          </div>
          <InputBox
            label="Description (max 255 characters)"
            name="description"
            onChange={setDescription}
            rows={8}
            type="text"
            useTextArea={true}
            value={description}
          />
          <InputDropdown
            currentValue={size}
            label="Scaling Size* (LARGE recommended)"
            name="size"
            onChange={setSize}
            options={["LARGE", "MEDIUM", "SMALL"]}
          />
          <div className="formButtonsContainer">
            <GlobalButton
              title="Cancel"
              backgroundColor="white"
              textColor="black"
              onClick={handleOnClickCloseForm}
              borderColor="black"
            />
            <GlobalButton
              title="Submit"
              backgroundColor="#DA1A32"
              textColor="white"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
