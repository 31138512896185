function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer">
      <p className="footerText">
        © {year} | Scheels |{" "}
        <a
          style={{ color: "white" }}
          href="https://api.kasada.io/v1-preview/scheduled-scaling-events/openapi/rapidoc#post-/v1-preview/orgs/-org-/scheduled-scaling-events"
        >
          Related Docs
        </a>
      </p>
    </div>
  );
}

export default Footer;
