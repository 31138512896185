import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
import { getEventsList } from "./getEventsList";
import "./App.css";
import LoadingSpinner from "./LoadingSpinner";

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [eventsList, setEventsList] = useState([]);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("client_id", process.env.REACT_APP_CLIENT_ID);
  urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  urlencoded.append("audience", process.env.REACT_APP_AUDIENCE);
  urlencoded.append("grant_type", process.env.REACT_APP_GRANT_TYPE);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  async function getInitialData() {
    setIsLoading(true);
    try {
      const response = await fetch(

        `${process.env.REACT_APP_BASEAUTHURL}/oauth/token`,
        requestOptions
      );
      const result = await response.json();
      setAccessToken(result.access_token);
      const list = await getEventsList(result.access_token);
      setEventsList(list);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="app">
      <Header setFormIsOpen={setFormIsOpen} />
      {isLoading ? (
        <div className="content">
          <LoadingSpinner />
        </div>
      ) : (
        <Content
          eventsList={eventsList}
          accessToken={accessToken}
          formIsOpen={formIsOpen}
          setFormIsOpen={setFormIsOpen}
        />
      )}
      <Footer />
    </div>
  );
}

export default App;
